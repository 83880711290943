import React from "react";
import clsx from "clsx";
import { animated, useInView } from "@react-spring/web";

const getChildrenOnDisplayName = (children, displayName) =>
  React.Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null,
  );

const colStyles = {
  1: "lg:grid-cols-1",
  2: "lg:grid-cols-2",
  3: "lg:grid-cols-3",
  4: "lg:grid-cols-4",
};

export default function Section({ children, cols, ...props }) {
  const header = getChildrenOnDisplayName(children, "Header");
  const cards = getChildrenOnDisplayName(children, "Card");
  const colStyle = colStyles[cols];

  return (
    <>
      <section {...props}>
        {header}
        <div
          className={clsx(
            colStyle,
            header.length > 0 ? "pt-8" : "pt-24",
            "pb-16 grid gap-4 grid-cols-1 space-y-8 lg:space-y-0",
          )}
        >
          {cards}
        </div>
      </section>
    </>
  );
}

const Header = ({ children }) => (
  <div className="pt-8 text-center">{children}</div>
);
Header.displayName = "Header";
Section.Header = Header;

const Card = ({ children }) => {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 100,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-5% 0%",
      once: true,
    },
  );

  return (
    <animated.div
      ref={ref}
      className="px-8 lg:px-20 text-center space-y-4 text-lg font-light"
      style={springs}
    >
      {children}
    </animated.div>
  );
};
Card.displayName = "Card";
Section.Card = Card;
