import { useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCameraCctv,
  faCode,
  faCompassDrafting,
  faCrystalBall,
  faHandshakeSimple,
  faHeadSideBrain,
  faSpinner,
  faTimelineArrow,
  faVideo,
  faWifi,
} from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";
import useContactService from "./hooks/ContactService";

function App() {
  const [createdId, setCreatedId] = useState(false);
  const { createContact } = useContactService();
  const ContactSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name."),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
    message: Yup.string().required("Please enter a message."),
  });

  return (
    <>
      <Header />
      <Section id="hero" cols="1" className="bg-black text-white">
        <Section.Card>
          <h1 className="text-6xl pb-4">
            Your technology and digital media partner.
          </h1>
          <p className="text-sm">
            We are multi-disciplinary digital media and technology specialists,
            delivering bespoke services and tailor-made designs.
          </p>
        </Section.Card>
      </Section>
      <Section id="about" cols="3" className="bg-white text-bcblue">
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faTimelineArrow} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Our Story</h1>
          <p>
            Bradley Consultancy was founded to provide innovative, cutting-edge,
            cost-effective solutions to a range of clients from businesses to
            community clubs to individuals across a wide range of sectors.
          </p>
          <p>
            We passionately believe technology and art can improve any human
            endeavour.
          </p>
          <p>
            We deliver bespoke white-glove services that are tailored to meet
            the unique needs of each client.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faCrystalBall} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Our Vision</h1>
          <p>
            We strive to be the leading provider of technology and media
            solutions that drive business growth and success.
          </p>
          <p>
            We are passionate about partnership and helping you solve your
            problems.
          </p>
          <p>
            We use appropriate technologies to create solutions that are
            innovative, efficient, and effective.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faHeadSideBrain} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Our Skills</h1>
          <p>
            Our team of experts is skilled in a range of technologies, including
            videography, photography, website design, cloud computing, WiFi
            hotspots, point-of-sale systems, reliability engineering, and more.
          </p>
          <p>
            We use these skills and technologies to create solutions that are
            reliable, secure, and scalable.
          </p>
        </Section.Card>
      </Section>
      <Section id="services" cols="4" className="bg-bcblue text-white">
        <Section.Header>
          <h1 className="text-4xl font-bold">Our Services</h1>
        </Section.Header>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faVideo} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Multimedia</h1>
          <p>
            We have a wealth of experience with both still photography, video
            direction, production and post-production.
          </p>
          <p>
            Whatever your message, we will capture, edit and produce footage
            that exceeds your expectations.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faCode} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Software</h1>
          <p>
            Our team has extensive experience writing bespoke software for a
            variety of clients, tailored to their unique requirements.
          </p>
          <p>
            We particularly enjoy helping clients simplify, streamline and
            optimise their workflows, and improving their own customers'
            experience with their products and services.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faWifi} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Networking</h1>
          <p>
            We specialise in network design and implementation, especially
            bringing WiFi to remote public spaces.
          </p>
          <p>
            We would be delighted to discuss your connectivity requirements with
            you.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faCameraCctv} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">CCTV</h1>
          <p>
            We take pride in designing the right CCTV solution for your home or
            business, using the right camera for the right circumstances.
          </p>
          <p>
            We will customise the installation to your exact requirements to
            deliver the solution that meets your aesthetic and security needs.
          </p>
        </Section.Card>
      </Section>
      <Section id="howwework" cols="3" className="bg-white text-bcblue">
        <Section.Header>
          <h1 className="text-4xl font-bold">How We Work</h1>
        </Section.Header>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faCompassDrafting} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Bespoke Solutions</h1>
          <p>
            We offer bespoke solutions that are designed to meet the unique
            needs of each client.
          </p>
          <p>
            Our team of experts works closely with you to understand your
            requirements and create solutions that are tailored to your needs.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faBoltLightning} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Fast Solutions</h1>
          <p>
            We understand that time is money, which is why we offer fast
            solutions that ensure our clients stay ahead of the competition.
          </p>
          <p>
            Our team efficiently delivers solutions that are quick and
            effective.
          </p>
        </Section.Card>
        <Section.Card>
          <p>
            <FontAwesomeIcon icon={faHandshakeSimple} className="h-12" />
          </p>
          <h1 className="text-3xl font-bold pb-2">Trusted Partners</h1>
          <p>
            Our team has a track record of partnering with clients for the long
            term.
          </p>
          <p>As your needs evolve so will the solutions we provide.</p>
        </Section.Card>
      </Section>
      <Section id="contact" cols="1" className="bg-bcblue text-white">
        <Section.Header>
          <h1 className="text-4xl font-bold">Contact Us</h1>
        </Section.Header>
        <Section.Card>
          {!createdId && (
            <>
              <p>
                To discuss your requirements or to schedule a free consultation,
                please fill in your contact details below and we will get back
                to you.
              </p>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                validationSchema={ContactSchema}
                onSubmit={(values, { setSubmitting }) => {
                  console.log("submitting", values);
                  createContact(values)
                    .then(function (res) {
                      setCreatedId(res.id);
                    })
                    .catch(function (res) {
                      throw res;
                    });
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <div className="text-bcblue">
                      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4">
                        <div>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="w-full"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-bcred italic text-left"
                          />
                        </div>
                        <div>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="w-full"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-bcred italic text-left"
                          />
                        </div>
                      </div>
                      <Field
                        as="textarea"
                        name="message"
                        placeholder="Tell us a bit about your project..."
                        className="w-full mt-2 md:mt-4"
                      ></Field>
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-bcred italic text-left"
                      />
                      <button
                        type="submit"
                        className={clsx(
                          !isValid || !dirty
                            ? "bg-bcblue text-white"
                            : "bg-white text-bcblue",
                          "rounded-md px-4 py-4 mt-4 font-bold border-2",
                        )}
                        disabled={!isValid || !dirty || isSubmitting}
                      >
                        <div className="flex items-center justify-center">
                          {isSubmitting && (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="h-6"
                              spin
                            />
                          )}
                          <div className={clsx(isSubmitting ? "pl-2" : "")}>
                            {isSubmitting ? "Sending..." : "Send"}
                          </div>
                        </div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {createdId && (
            <>
              <p>Thank you for your message!</p>
              <p>We look forward to helping you with your enquiry.</p>
            </>
          )}
        </Section.Card>
      </Section>
      <Footer />
    </>
  );
}

export default App;
