export default function Footer() {
  return (
    <footer className="bg-white h-16 place-content-center">
      <div className="text-center text-xs font-light text-bcblue px-8">
        &copy;2024 Bradley Consultancy Limited is a registered company in
        England and Wales (Company Number{" "}
        <a href="https://find-and-update.company-information.service.gov.uk/company/14898224">
          14898224
        </a>
        ). 86-90 Paul Street, London, England, United Kingdom, EC2A 4NE. All
        rights reserved.
      </div>
    </footer>
  );
}
